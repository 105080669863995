<!-- <div class="content-wrapper"> -->
<div class="container-fluid p-0">
    <div class="row flex-nowrap content-wrapper">
        <div class="col-auto px-0">
            <div id="sidebar" class="left-nav collapse collapse-horizontal show">
                <app-left-side></app-left-side>
            </div>
        </div>
        <main class="col ps-md-2 pt-2 content-overflow">
            <div class="right-content">
                <!-- <div class="col-lg-3 p-4">
                  <app-left-side></app-left-side>
               </div> -->
                <div class="px-4 py-3">
                    <!-- <a href="javascript:void"><i class="icon-material-outline-arrow-left-alt text-primary mr-2 arrow-l  cursor" ></i>
                  <i class="icon-material-outline-arrow-right-alt text-primary arrow-l mr-2 cursor" ></i></a> -->
                    <div class="row">
                        <div class="col-sm-12">
                            <div class="row">
                                <div class="col-xl-3 col-md-6">
                                    <div class="card rounded-4 mb-4">
                                        <div class="card-body py-3 px-2 d-flex align-items-center">
                                            <div class="col-9">
                                                <h3 class="box-title"><strong>HWC </strong> Receipts</h3>
                                                <h2 class="figure">0 </h2>
                                            </div>
                                            <div class="box-icon rounded-circle">
                                                <i class="fa fa-file-text" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6">
                                    <div class="card rounded-4 mb-4">
                                        <div class="card-body py-3 px-2 d-flex align-items-center">
                                            <div class="col-9">
                                                <h3 class="box-title"><strong>PGC </strong> Receipts</h3>
                                                <h2 class="figure">0 </h2>
                                            </div>
                                            <div class="box-icon rounded-circle">
                                                <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6">
                                    <div class="card rounded-4 mb-4">
                                        <div class="card-body py-3 px-2 d-flex align-items-center">
                                            <div class="col-9">
                                                <h3 class="box-title"><strong>LITINDIA </strong> Receipts</h3>
                                                <h2 class="figure">0 </h2>
                                            </div>
                                            <div class="box-icon rounded-circle">
                                                <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-xl-3 col-md-6">
                                    <div class="card rounded-4 mb-4">
                                        <div class="card-body py-3 px-2 d-flex align-items-center">
                                            <div class="col-9">
                                                <h3 class="box-title"><strong>Total Sent </strong> Receipts</h3>
                                                <h2 class="figure">0 </h2>
                                            </div>
                                            <div class="box-icon rounded-circle">
                                                <i class="fa fa-file-text-o" aria-hidden="true"></i>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>



                        <!--<div class="col-lg-8">
                        <div class="row">
                           <div class="col-md-12">
                              <div class="card rounded-4 mb-4 bg-white card-box">
                                 <div class="card-head d-flex">
                                    <header class="flex-grow-1">Users <span>(17)</span></header>
                                    <div class="tools">
                                       <button type="button" class="btn btn-outline-secondary add-btn">
                                          Add New 
                                       </button>
                                       <button class="accordion-button btn-icon" type="button">
                                          <i class="fa fa-cog"></i>
                                        </button>
                                       <button class="accordion-button with-chevron btn-icon" type="button" data-bs-toggle="collapse" data-bs-target="#tbcollapse" aria-expanded="true" aria-controls="collapseOne">
                                          <i class="fa fa-angle-down"></i>
                                        </button> 
                                    </div>
                                 </div>
                                 <div class="card-body p-0" id="tbcollapse">
                                    <div class="table-wrap">
                                       <div class="table-responsive">
                                          <table class="table table-hover display product-overview mb-30" id="support_table">
                                             <thead>
                                                <tr>
                                                   <th>#</th>
                                                   <th>Name</th>
                                                   <th>Email </th>
                                                   <th>Title</th>
                                                   <th>Date of Birth</th>
                                                   <th>Actions</th>
                                                </tr>
                                             </thead>
                                             <tbody>
                                                <tr>
                                                   <td>1</td>
                                                   <td class="user-img">
                                                      <img src="https://www.einfosoft.com/templates/admin/smart/source/assets/img/user/user1.jpg" alt=""> 
                                                      Michael Scott
                                                   </td>
                                                   <td>marshall@yahoo.com</td>
                                                   <td>
                                                      <span class="badge text-bg-success">Regional Manager</span>
                                                   </td>
                                                   <td>07/04/1960</td>
                                                   <td>
                                                      <a href="javascript:void(0)" class="tblDelBtn">
                                                         <i class="fa fa-trash-o"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblEditBtn">
                                                         <i class="fa fa-pencil"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblviewBtn">
                                                         <i class="fa fa-eye"></i>
                                                      </a>
                                                   </td>
                                                </tr>
      
                                                <tr>
                                                   <td>2</td>
                                                   <td class="user-img">
                                                      <img src="assets/img/profile.jpg" alt=""> 
                                                      Jai Ram Vishwakarma
                                                   </td>
                                                   <td>jairam@concientotech.com</td>
                                                   <td>
                                                      <span class="badge text-bg-warning">UI/UX Designer</span>
                                                   </td>
                                                   <td>04/02/1983</td>
                                                   <td>
                                                      <a href="javascript:void(0)" class="tblDelBtn">
                                                         <i class="fa fa-trash-o"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblEditBtn">
                                                         <i class="fa fa-pencil"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblviewBtn">
                                                         <i class="fa fa-eye"></i>
                                                      </a>
                                                   </td>
                                                </tr>
                                                
                                                <tr>
                                                   <td>3</td>
                                                   <td class="user-img">
                                                      <img src="assets/img/profile.jpg" alt=""> 
                                                      Jim Halpert
                                                   </td>
                                                   <td>miles@mail.com</td>
                                                   <td>
                                                      <span class="badge text-bg-danger">Salesman</span>
                                                   </td>
                                                   <td>08/08/1984</td>
                                                   <td>
                                                      <a href="javascript:void(0)" class="tblDelBtn">
                                                         <i class="fa fa-trash-o"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblEditBtn">
                                                         <i class="fa fa-pencil"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblviewBtn">
                                                         <i class="fa fa-eye"></i>
                                                      </a>
                                                   </td>
                                                </tr>
                                                
                                                <tr>
                                                   <td>4</td>
                                                   <td class="user-img">
                                                      <img src="assets/img/profile.jpg" alt=""> 
                                                      Pam Beesly
                                                   </td>
                                                   <td>gamble@outlook.com</td>
                                                   <td>
                                                      <span class="badge text-bg-danger">Salesman</span>
                                                   </td>
                                                   <td>19/10/1992</td>
                                                   <td>
                                                      <a href="javascript:void(0)" class="tblDelBtn">
                                                         <i class="fa fa-trash-o"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblEditBtn">
                                                         <i class="fa fa-pencil"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblviewBtn">
                                                         <i class="fa fa-eye"></i>
                                                      </a>
                                                   </td>
                                                </tr>
      
                                                
                                                <tr>
                                                   <td>5</td>
                                                   <td class="user-img">
                                                      <img src="assets/img/profile.jpg" alt=""> 
                                                      Kevin Malone
                                                   </td>
                                                   <td>castaneda@mail.com</td>
                                                   <td>
                                                      <span class="badge text-bg-pinkish">Accountant</span>
                                                   </td>
                                                   <td>01/02/1952</td>
                                                   <td>
                                                      <a href="javascript:void(0)" class="tblDelBtn">
                                                         <i class="fa fa-trash-o"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblEditBtn">
                                                         <i class="fa fa-pencil"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblviewBtn">
                                                         <i class="fa fa-eye"></i>
                                                      </a>
                                                   </td>
                                                </tr>
      
                                                
                                                <tr>
                                                   <td>6</td>
                                                   <td class="user-img">
                                                      <img src="assets/img/profile.jpg" alt=""> 
                                                      Phyllis Vance
                                                   </td>
                                                   <td>hendricks@yahoo.com</td>
                                                   <td>
                                                      <span class="badge text-bg-danger">Salesman</span>
                                                   </td>
                                                   <td>25/12/1962</td>
                                                   <td>
                                                      <a href="javascript:void(0)" class="tblDelBtn">
                                                         <i class="fa fa-trash-o"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblEditBtn">
                                                         <i class="fa fa-pencil"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblviewBtn">
                                                         <i class="fa fa-eye"></i>
                                                      </a>
                                                   </td>
                                                </tr>
      
                                                
                                                <tr>
                                                   <td>7</td>
                                                   <td class="user-img">
                                                      <img src="assets/img/profile.jpg" alt=""> 
                                                      Meredith Palmer
                                                   </td>
                                                   <td>kelly@mail.com</td>
                                                   <td>
                                                      <span class="badge text-bg-info">Supplier Relations</span>
                                                   </td>
                                                   <td>17/09/1971</td>
                                                   <td>
                                                      <a href="javascript:void(0)" class="tblDelBtn">
                                                         <i class="fa fa-trash-o"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblEditBtn">
                                                         <i class="fa fa-pencil"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblviewBtn">
                                                         <i class="fa fa-eye"></i>
                                                      </a>
                                                   </td>
                                                </tr>
      
                                                
                                                <tr>
                                                   <td>8</td>
                                                   <td class="user-img">
                                                      <img src="assets/img/profile.jpg" alt=""> 
                                                      Angela Martin
                                                   </td>
                                                   <td>ryan@mail.com</td>
                                                   <td>
                                                      <span class="badge text-bg-primary">Accountant</span>
                                                   </td>
                                                   <td>05/12/1997</td>
                                                   <td>
                                                      <a href="javascript:void(0)" class="tblDelBtn">
                                                         <i class="fa fa-trash-o"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblEditBtn">
                                                         <i class="fa fa-pencil"></i>
                                                      </a>
                                                      <a href="javascript:void(0)" class="tblviewBtn">
                                                         <i class="fa fa-eye"></i>
                                                      </a>
                                                   </td>
                                                </tr>
                                             </tbody>
                                          </table>
                                       </div>
                                    </div>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>-->
                        <!--<div class="col-lg-4">
                        <div class="card rounded-4 mb-4 bg-white card-box">
                           <div class="card-head d-flex">
                              <header class="flex-grow-1">Team Member <span>(10)</span></header>
                              <div class="tools">
                                 <button class="accordion-button btn-icon" type="button">
                                    <i class="fa fa-cog"></i>
                                  </button>
                                 <button class="accordion-button with-chevron btn-icon" type="button" data-bs-toggle="collapse" data-bs-target="#teamMcollapse" aria-expanded="true" aria-controls="collapseOne">
                                    <i class="fa fa-angle-down"></i>
                                  </button> 
                              </div>
                           </div>
                           
                           <div class="card-body p-3" id="teamMcollapse">
                              <div class="media mb-4" *ngFor="let s of members; let i = index">
                                 <img src="assets/img/pr.png" alt="John Doe" class="mr-3 mb-3 rounded-circle img-fluid">
                                 <div class="media-body">
                                    <h6 class="mb-0">John Doe</h6>
                                    <p class="mb-0"><small>Full Stack Developer</small></p>
                                 </div>
                              </div>
                           </div>
                        </div>
                     </div>-->
                    </div>

                </div>
            </div>
        </main>
    </div>
</div>