<div class="container-fluid p-0">
    <div class="row flex-nowrap content-wrapper">
        <div class="col-auto px-0">
            <div id="sidebar" class="left-nav collapse collapse-horizontal show">
                <app-left-side></app-left-side>
            </div>
        </div>
        <main class="col ps-md-2 pt-2 content-overflow">
            <div class="right-content">
                <div class="px-4 py-3">
                    <div class="row">
                        <div class="col-lg-12" *ngIf="receiptList && receiptList.length > 0">
                            <div class="card rounded-4 mb-4 bg-white card-box">
                                <div class="card-head d-flex">
                                    <header class="flex-grow-1">Receipt List <span>(#)</span></header>
                                    <div class="tools">
                                        <button (click)="showAddModal(content)" type="button" class="btn btn-outline-secondary add-btn">
                                        Add New Receipt 
                                      </button>
                                    </div>
                                </div>
                                <div class="card-body p-0" id="tbcollapse">
                                    <div class="table-wrap">
                                        <div class="table-responsive">
                                            <table class="table table-hover display product-overview mb-30" datatable [dtOptions]="dtOptions">
                                                <thead>
                                                    <tr>
                                                        <th>Invoice Id</th>
                                                        <th>Name</th>
                                                        <th>Email</th>
                                                        <th>Pan Card</th>
                                                        <th>Mobile</th>
                                                        <th>Amount</th>
                                                        <th>Source</th>
                                                        <th>Date</th>
                                                        <th>Status</th>
                                                        <th>Action</th>
                                                    </tr>
                                                </thead>
                                                <tbody>
                                                    <tr *ngFor="let list of receiptList;let i = index">
                                                        <td>{{list.invoiceid}}</td>
                                                        <td>{{list.name}}</td>
                                                        <td>{{list.email}}</td>
                                                        <td>{{list.pancard}}</td>
                                                        <td>{{list.mobile}}</td>
                                                        <td>₹ {{list.txnamount}}</td>
                                                        <td>{{list.source}}</td>
                                                        <td>{{list.createdat | date : 'YYYY-MM-dd h:mm:ss a'}}</td>
                                                        <td>{{list.invoicetype}} | {{list.status}}</td>
                                                        <td class="actionsTD">
                                                            <a data-bs-toggle="tooltip" data-bs-placement="top" data-bs-title="Send Receipt" *ngIf="(list.status | uppercase) =='SUCCESS' || (list.status | uppercase) =='TXN_SUCCESS'" style="margin-bottom:3px;" class="tblviewBtn" (click)="sendReceiptEmail(list.ptid, true, list.name, list.invoiceid)">
                                                                <i class="fa fa-paper-plane" aria-hidden="true"></i>
                                                            </a>
                                                            <a *ngIf="(list.status | uppercase) =='SUCCESS' || (list.status | uppercase) =='TXN_SUCCESS'" class="tblEditBtn" (click)="downloadReceipt(list.ptid, false)">
                                                                <i class="fa fa-download" aria-hidden="true"></i>
                                                            </a>
                                                            <a class="tblEditBtn" (click)="discardReceipt(list.ptid, list.name, list.invoiceid)">
                                                                <i class="fa fa-trash" aria-hidden="true"></i>
                                                            </a>
                                                            <a class="tblEditBtn" (click)="showUpdateModal(list, i, updateReceipt)">
                                                                <i class="fa fa-edit" aria-hidden="true"></i>
                                                            </a>
                                                        </td>
                                                    </tr>
                                                </tbody>
                                            </table>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>

<ng-template #content let-modal>
    <div class="modal-content shadow-lg">
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-6">
                    <h6>{{action}} Receipt</h6>
                </div>
                <div class="col-lg-6 text-lg-right" (click)="modal.dismiss()">
                    <i aria-label="Close" class="icon-feather-x cursor text-primary"></i>
                </div>
            </div>
            <div class="card-body">
                <form [formGroup]="userForm">
                    <div class="row">
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Name *</label>
                                <input type="text" formControlName="name" class="form-control bg-light" placeholder="Name" />
                                <div *ngIf="showErr && userForm.controls.name.errors" class="invalid-feedback">
                                    <div *ngIf="userForm.controls.name.errors.required">Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Email *</label>
                                <input type="email" formControlName="email" class="form-control bg-light" placeholder="Email" />
                                <div *ngIf="showErr && userForm.controls.email.errors" class="invalid-feedback">
                                    <div *ngIf="userForm.controls.email.errors.required">Email is required</div>

                                    <div *ngIf="userForm.controls.email.errors.required">User Email is required</div>
                                    <div *ngIf="userForm.controls.email.errors.email">User Email must be a valid email address
                                    </div>
                                    <div *ngIf="userForm.controls.email.errors.maxlength">Email should be Max 50</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Mobile *</label>
                                <input type="text" formControlName="mobile" class="form-control bg-light" placeholder="Mobile" />
                                <div *ngIf="showErr && userForm.controls.mobile.errors" class="invalid-feedback">

                                    <div *ngIf="userForm.controls.mobile.errors.required">Phone number is required</div>
                                    <div *ngIf="userForm.controls.mobile.errors.minlength">Please Enter 10 digit Mobile Number
                                    </div>
                                    <div *ngIf="userForm.controls.mobile.errors.validatePhoneNumber">Please Enter 10 digit Mobile Number
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Amount *</label>
                                <input type="text" formControlName="txnamount" class="form-control bg-light" placeholder="Amount" />
                                <div *ngIf="showErr && userForm.controls.txnamount.errors" class="invalid-feedback">
                                    <div *ngIf="userForm.controls.txnamount.errors.required">Amount is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Source *</label>
                                <select formControlName="source" class="form-control bg-light">
                                    <option *ngFor="let s of sourceList" [value]="s.sourcename">{{ s.sourcevalue }}
                                    </option>
                                </select>
                                <div *ngIf="showErr && userForm.controls.source.errors" class="invalid-feedback">
                                    <div *ngIf="userForm.controls.source.errors.required">Source is required</div>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="col-lg-6">
                            <div class="form-group">
                                <label>Campaignid *</label>
                                <input type="text" formControlName="campaignid" class="form-control bg-light" placeholder="Campaignid" />
                                <div *ngIf="showErr && userForm.controls.campaignid.errors" class="invalid-feedback">
                                    <div *ngIf="userForm.controls.campaignid.errors.required">Campaignid is required
                                    </div>
                                </div>
                            </div>
                        </div> -->
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Payment Mode *</label>
                                <select formControlName="paymentmode" class="form-control bg-light">
                                    <option *ngFor="let p of paymentModeList" [value]="p.paymenttype">{{ p.paymentname
                                        }}</option>
                                </select>
                                <div *ngIf="showErr && userForm.controls.paymentmode.errors" class="invalid-feedback">
                                    <div *ngIf="userForm.controls.paymentmode.errors.required">Payment Mode is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Bank Txnid </label>
                                <input type="text" formControlName="banktxnid" class="form-control bg-light" placeholder="Bank Txnid" />

                            </div>
                        </div>
                        <div class="col-lg-6">
                            <div class="form-group">
                                <label>Pan Card *</label>
                                <input type="text" formControlName="pancard" class="form-control bg-light" placeholder="Pan Card " />
                                <div *ngIf="showErr && userForm.controls.pancard.errors" class="invalid-feedback">
                                    <div *ngIf="userForm.controls.pancard.errors.required">Pan Card is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Donationurl *</label>
                                <input type="text" formControlName="donationurl" class="form-control bg-light" placeholder="Donationurl" />
                                <div *ngIf="showErr && userForm.controls.donationurl.errors" class="invalid-feedback">
                                    <div *ngIf="userForm.controls.donationurl.errors.required">Donationurl is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Reason *</label>
                                <input type="text" formControlName="reason" class="form-control bg-light" placeholder="Reason" />
                                <div *ngIf="showErr && userForm.controls.reason.errors" class="invalid-feedback">
                                    <div *ngIf="userForm.controls.reason.errors.required">Reason is required
                                    </div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <button (click)="submitModal(modal)" class="btn btn-main text-center m-auto mt-4">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #updateReceipt let-modal>
    <div class="modal-content shadow-lg">
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-6">
                    <h6>Update Receipt - {{selectedReceipt?.invoiceid}} - {{selectedReceipt.name}}</h6>
                </div>
                <div class="col-lg-6 text-lg-right" (click)="modal.dismiss()">
                    <i aria-label="Close" class="icon-feather-x cursor text-primary"></i>
                </div>
            </div>
            <div class="card-body">
                <form [formGroup]="uReceiptForm">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="name2">Name *</label>
                                <input type="text" formControlName="name" [ngModel]="selectedReceipt?.name" id="name2" class="form-control bg-light" placeholder="Name" />
                                <div *ngIf="showErr && uReceiptForm.controls.name.errors" class="invalid-feedback">
                                    <div *ngIf="uReceiptForm.controls.name.errors.required">Name is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="txnamount2">Amount *</label>
                                <input type="number" formControlName="txnamount" [ngModel]="strToNumber(selectedReceipt?.txnamount)" id="txnamount2" class="form-control bg-light" min="1" placeholder="Amount" />
                                <div *ngIf="showErr && uReceiptForm.controls.txnamount.errors" class="invalid-feedback">
                                    <div *ngIf="uReceiptForm.controls.txnamount.errors.required">Amount is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="pancard2">Pancard</label>
                                <input type="text" formControlName="pancard" [ngModel]="selectedReceipt?.pancard" id="pancard2" class="form-control bg-light" placeholder="pan card" />
                                <div *ngIf="showErr && uReceiptForm.controls.pancard.errors" class="invalid-feedback">
                                    <div *ngIf="uReceiptForm.controls.pancard.errors.required">Pancard is required</div>
                                </div>
                            </div>
                        </div>
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="reason2">Reason</label>
                                <input type="text" formControlName="reason" [ngModel]="selectedReceipt?.reason" id="reason2" class="form-control bg-light" placeholder="Reason" />
                                <div *ngIf="showErr && uReceiptForm.controls.reason.errors" class="invalid-feedback">
                                    <div *ngIf="uReceiptForm.controls.reason.errors.required">Reason is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="donationurl2">Donation URL</label>
                                <input type="text" formControlName="donationurl" [ngModel]="selectedReceipt?.donationurl" id="donationurl2" class="form-control bg-light" placeholder="Donation URL" />
                                <div *ngIf="showErr && uReceiptForm.controls.donationurl.errors" class="invalid-feedback">
                                    <div *ngIf="uReceiptForm.controls.donationurl.errors.required">Donation URL is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <button (click)="updateReceiptSubmit(modal)" class="btn btn-main text-center m-auto mt-4">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>


<!--<ng-template #importcsv let-modal>
    <div class="modal-content shadow-lg">
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-6">
                    <h6>Import CSV</h6>
                </div>
                <div class="col-lg-6 text-lg-right" (click)="modal.dismiss()">
                    <i aria-label="Close" class="icon-feather-x cursor text-primary"></i>
                </div>
            </div>
            <div class="card-body">
                <form [formGroup]="importCSVForm">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label for="formFileMultiple" class="form-label">Supporting Document
                                    Attach</label>
                                <input class="form-control" type="file" id="formFileMultiple" (change)="ondocumentSelectFile($event)"><br />
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <button (click)="submitImportCSVModal(modal)" class="btn btn-main text-center m-auto mt-4">Submit</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>


<ng-template #download let-modal>
    <div class="modal-content shadow-lg">
        <div class="modal-body">
            <div class="row">
                <div class="col-lg-6">
                    <h6>Download Receipt</h6>
                </div>
                <div class="col-lg-6 text-lg-right" (click)="modal.dismiss()">
                    <i aria-label="Close" class="icon-feather-x cursor text-primary"></i>
                </div>
            </div>
            <div class="card-body">
                <form [formGroup]="downloadForm">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="form-group">
                                <label>Payment Id *</label>
                                <input type="text" formControlName="ptid" class="form-control bg-light" placeholder="Payment Id" />
                                <div *ngIf="showErr && downloadForm.controls.ptid.errors" class="invalid-feedback">
                                    <div *ngIf="downloadForm.controls.ptid.errors.required">Payment Id is required</div>
                                </div>
                            </div>
                        </div>

                        <div class="col-lg-12">
                            <button (click)="submitDownloadModal(modal)" class="btn btn-main text-center m-auto mt-4">Download</button>
                        </div>
                    </div>
                </form>
            </div>
        </div>
    </div>
</ng-template>-->