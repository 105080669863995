<div class="container-fluid p-0">
    <div class="row flex-nowrap content-wrapper">
        <div class="col-auto px-0">
            <div id="sidebar" class="left-nav collapse collapse-horizontal show">
				<app-left-side></app-left-side>
			</div>
		</div>
		<main class="col ps-md-2 pt-2 content-overflow">
            <div class="right-content">
                <div class="px-4 py-3">
					<!--a href="javascript:void"><i class="icon-material-outline-arrow-left-alt text-primary mr-2 arrow-l  cursor" ></i> <i class="icon-material-outline-arrow-right-alt text-primary arrow-l mr-2 cursor" ></i></a-->
					<div class="row">
						<div class="col-lg-3">
							<div class="card rounded-4 mb-4 bg-white card-box">
								<div class="card-body text-center">
									<div class="profile-userpic">
										<img src="assets/img/profile.jpg" class="img-responsive" alt=""> 
									</div>
									<div class="profile-usertitle">
										<div class="profile-usertitle-name">{{auth.currentUser?.firstName}} {{auth.currentUser?.lastName}} </div>
										<div class="profile-usertitle-job"> {{auth.currentUser?.email}}</div>
									</div>
									<div class="d-flex justify-content-center mt-3">
										<button type="button" class="btn btn-primary mr-3">Chat</button>
										<button type="button" class="btn btn-secondary">Follow</button>
									</div>
								</div>								
							</div>
							<div class="card rounded-4 mb-4 bg-white card-box">
								<div class="card-head bdr-btm">
									<header>Quick Links</header>
								</div>
								<div class="card-body">
									<ul class="performance-list">
										<li>
											<a href="javascript:void(0)">
												<i class="fa fa-user-o" style="color:#F39C12;"></i> My Profile
											</a>
										</li>
										<li>
											<a href="#">
												<i class="fa fa-pencil-square-o" style="color:#DD4B39;"></i> Edit Profile
											</a>
										</li>
										<li>
											<a href="#">
												<i class="fa fa-key" style="color:#00A65A;"></i> Reset Password
											</a>
										</li>
										<li>
											<a href="javascript:void(0)" (click)="logout()" >
												<i class="fa fa-sign-out" style="color:#F39C12;"></i> My Logout
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-9">
							<div class="card rounded-4 mb-4 bg-white card-box">
								<div class="card-head bdr-btm">
									<header class="">My Profile</header>
								</div>
								<div class="card-body form-update">
									<form>
										<div class="row">
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">First Name</label>
													<input type="text" class="form-control bg-light" value="{{auth.currentUser?.firstName}}" placeholder="First Name" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Last Name</label>
													<input type="text"  value="{{auth.currentUser?.lastName}}" class="form-control bg-light" placeholder="Last Name" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Middle Name</label>
													<input type="text" value="{{auth.currentUser?.middleName}}" class="form-control bg-light" placeholder="Middle Name" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Phone No</label>
													<input type="text" value="{{auth.currentUser?.phoneNumber}}" class="form-control bg-light" placeholder="Phone No" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Dob</label>
													<input type="text" value="{{auth.currentUser?.dob}}" class="form-control bg-light" placeholder="10-june-2001" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Gender</label>
													<input type="text" value="{{auth.currentUser?.gender}}" class="form-control bg-light" placeholder="Gender" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Email</label>
													<input type="email" value="{{auth.currentUser?.email}}" class="form-control bg-light" placeholder="Enter Email Id" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Password</label>
													<input type="password"  class="form-control bg-light" placeholder="Enter Password" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Address1</label>
													<input type="text" value="{{auth.currentUser?.address1}}" class="form-control bg-light" placeholder="222 Sector 62" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Address2</label>
													<input type="text" value="{{auth.currentUser?.address2}}" class="form-control bg-light" placeholder="Nodia" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">City</label>
													<input type="text" value="{{auth.currentUser?.city}}" class="form-control bg-light" placeholder="City" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">State</label>
													<input type="text" value="{{auth.currentUser?.state}}" class="form-control bg-light" placeholder="State" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Country</label>
													<input type="text" value="{{auth.currentUser?.country}}" class="form-control bg-light" placeholder="Country" id="compaign">
												</div>
											</div>
											<div class="col-lg-6">
												<div class="form-group">
													<label for="text">Postal Code</label>
													<input type="text" value="{{auth.currentUser?.postalcode}}" class="form-control bg-light" placeholder="Enter Postal Code" id="compaign">
												</div>
											</div>
											<div class="col-lg-12">
												<div class="form-group text-center">
													<button type="submit" class="btn btn-main text-center mt-4">Submit</button>
												</div>
											</div>											
										</div>
									</form>
								</div>
							</div>
							<!-- <div class="shadow-lg rounded-lg bg-white p-4">
								<h5 class="text-primary">My Profile</h5>
								
							</div> -->
						</div>
					</div>
				</div>
			</div>
		</main>	
	</div>
</div>			