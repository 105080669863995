
export default class Adapter {
  loader;
  reader;
  config;
  t;
  fileSrc: string | ArrayBuffer;
  constructor(loader, config, t) {
    this.loader = loader;
    this.t = t; // Translate function
    this.config = config;   
  }


public async upload(): Promise<any> {
  const value = await this.loader.file;
      console.log(value);
      return this.read(value);
    }

  read(file) {
    let f = file.name;
    //console.log(f);
    //localStorage.setItem('img', f);
    return new Promise((resolve, reject) => {
      const reader = new FileReader();

     // reader.onload = function () {
      // localStorage.setItem('base64code', reader.result);
      //  resolve({ default: reader.result });
     // };
      reader.onload = (e) => {
        let reader = e.target;
       let base64 = reader.result;
       //let base64 = reader.result as string;
      // const t = base64.slice(22);
       // localStorage.setItem('base64code', t);
       // console.log(t);  
      // let buffer=Uint8Array.from(atob(base64), c => c.charCodeAt(0));
       // let blob=new Blob([t], { type: "image/png" });
      //  let url=URL.createObjectURL(file.name);
       // console.log(url)
        //let img=document.createElement("img");
        //console.log(img)
      //  img.src=url;
       // document.body.appendChild(img);
      //  console.log(this.fileSrc.split())
      //  let arr = this.fileSrc.split(',');
      //  let  mime = arr[0].match(/:(.*?);/)[1];
      //  let file=  this.fileSrc.match(/[^\\/]+\.[^\\/]+$/);
      // image.src = 'data:image/png;base64' + t;
      //  console.log(file);
       // let g = localStorage.getItem('localUrl');
        // console.log(g);
        //document.body.appendChild(image);
        resolve({default: base64});
       // localStorage.removeItem('burl');
      // this.fileSelected.emit(file);
    }


      reader.onerror = function (error) {
        reject(error);
      };

      reader.onabort = function () {
        reject();
      };
      
      reader.readAsDataURL(file);
    });
  }

  abort() {
    if (this.reader) {
      this.reader.abort();
    }
  }
}

