<div class="container-fluid p-0">
    <div class="row flex-nowrap content-wrapper">
        <div class="col-auto px-0">
            <div id="sidebar" class="left-nav collapse collapse-horizontal show">
                <app-left-side></app-left-side>
            </div>
        </div>
        <main class="col ps-md-2 pt-2 content-overflow">
            <div class="right-content">
                <div class="px-4 py-3"> 
                    <div class="card rounded-4 mb-4 bg-white card-box">
                        <div class="card-head d-flex">
                        <header class="flex-grow-1">{{selectedType}} List</header>
                        <div class="tools">
                            <!-- <a *ngIf="selectedType=='Blog'" class="btn btn-primary text-primary text-underline cursor" (click)="showAddModal(content, 'Blog')">+ Add Blog</a>
                            <a *ngIf="selectedType=='News'" class="btn btn-primary text-primary text-underline cursor" style="margin-left:5px" (click)="showAddModal(content, 'News')">+ Add News</a> -->
                            <button *ngIf="selectedType=='Blog'" type="button" class="btn btn-outline-secondary add-btn" (click)="showAddModal(content, 'Blog')">
                                Add Blog 
                            </button>
                            <button *ngIf="selectedType=='News'" type="button" class="btn btn-outline-secondary add-btn" (click)="showAddModal(content, 'News')">
                                Add News 
                            </button>
                            <button class="accordion-button with-chevron btn-icon" type="button">
                                <i class="fa fa-cog"></i>
                            </button>
                            <button class="accordion-button with-chevron btn-icon" type="button" data-bs-toggle="collapse" data-bs-target="#tbcollapse" aria-expanded="true" aria-controls="collapseOne">
                                <i class="fa fa-angle-down"></i>
                            </button> 
                        </div>
                        </div>
                        <div class="card-body p-0" id="tbcollapse">
                            <div class="table-wrap">
                                <div class="table-responsive">
                                    <table class="table table-hover display product-overview mb-30">
                                        <thead>
                                            <tr>
                                                <th class="serial">S.No</th>
                                                <th class="title">Title</th>
                                                <th class="category">Category</th>
                                                <th class="category">Status</th>
                                                <th class="actions">Actions</th>
                                            </tr>
                                        </thead>
                                        <tbody>
                                            <tr *ngFor="let b of blogs.list; let i = index" [class.deleted]="b.status == 'deleted'">
                                                <td class="serial"> {{ i+1 }} </td>
                                                <td class="title"> {{ b.title }} </td>
                                                <td class="category"> {{ b.categoryname }} </td>
                                                <td class="staus"> {{ b.staus }} </td>
                                                <td class="actions">
                                                    <span *ngIf="b.status != 'deleted'">
                                                        <a href="javascript:void(0)" class="tblEditBtn" (click)="showEdit(content, b)">
                                                            <i class="fa fa-pencil"></i>
                                                        </a>
                                                        <!-- <i class="icon-feather-edit text-primary mr-2 cursor"
                                                            ></i> -->

                                                            <a href="javascript:void(0)" class="tblDelBtn" (click)="confirmDel(delConf, b)">
                                                                <!-- <i class="fa fa-trash-o"></i> -->
                                                                <i class="fa fa-trash-o"></i>
                                                            </a>
                                                        
                                                    </span>
                                                    <span *ngIf="b.status == 'deleted'">
                                                        <a href="javascript:void(0)" class="tblEditBtn" (click)="showEdit(content, b)">
                                                            <i class="fa fa-pencil"></i>
                                                        </a>
                                                        
                                                    </span>
                                                </td>
                                            </tr>
                                        </tbody>
                                    </table>
                                    <div class="d-flex justify-content-between p-2 my-pag">
                                        <ngb-pagination [collectionSize]="blogs.options.total" [pageSize]="blogs.options.limit" [(page)]="blogs.options.pageno" [maxSize]="5" [rotate]="true" (pageChange)="onPagination($event)"></ngb-pagination>
                                        <select class="custom-select pagination" [(ngModel)]="blogs.options.limit" (ngModelChange)="setPageSize()">
                                            <option [ngValue]="10">10 items per page</option>
                                            <option [ngValue]="50">50 items per page</option>
                                            <option [ngValue]="100">100 items per page</option>
                                        </select>
                                    </div>
                                </div>
                            </div> 
                        </div>
                    </div>
                </div>
            </div>
        </main>
    </div>
</div>
<ng-template #content let-modal>
    <div class="modal-content shadow-lg">
        <div class="modal-header">
            <h5 class="modal-title" id="exampleModalLabel">{{ action}} {{selectedType}}</h5>
            <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
        </div>
        <div class="modal-body">
            <div class="">
                <!-- <div class="row ">
                    <div class="col-lg-6">
                        <h6></h6>
                    </div>
                    <div class="col-lg-6 text-lg-right" (click)="modal.dismiss()">
                        <i aria-label="Close" class="icon-feather-x cursor text-primary"></i>
                    </div>
                </div> -->
                <div class="card-body">
                    <div class="form-step-header">
                        <div class="step" [class.active]="step == 1">
                            <span class="step-circle">1</span>
                            <span class="step-label">{{selectedType}} Title</span>
                        </div>
                        <div class="line"></div>
                        <div class="step" [class.active]="step == 2">
                            <span class="step-circle">2</span>
                            <span class="step-label">{{selectedType}} Body</span>
                        </div>
                    </div>
                    <form class="p-0" [formGroup]="blogForm">
                        <div class="content" [hidden]="step != 1">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Title *</label>
                                        <input type="text" formControlName="title" class="form-control bg-light" [ngClass]="{ 'is-invalid': errStep1 && f.title.errors }" placeholder="Title*" (keypress)="updateBlogNewsSlug()" />
                                        <div *ngIf="errStep1 && f.title.errors" class="invalid-feedback">
                                            <div *ngIf="f.title.errors.required">Title is required.</div>
                                            <div *ngIf="(f.title.hasError('maxlength') || f.title.hasError('minlength')) && !f.title.hasError('required')">
                                                Title should be 30 - {{titleLength}} characters.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Slug *</label>
                                        <input type="text" formControlName="slug" class="form-control bg-light" [ngClass]="{ 'is-invalid': errStep1 && f.slug.errors }" placeholder="Slug*" />
                                        <div *ngIf="errStep1 && f.slug.errors" class="invalid-feedback">
                                            <div *ngIf="f.slug.errors.required">Slug is required.</div>
                                            <div *ngIf="(f.slug.hasError('maxlength') || f.slug.hasError('minlength')) && !f.slug.hasError('required')">
                                                Slug should be 30 - {{titleLength}} characters.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Category *</label>
                                        <select formControlName="categoryid" class="form-control bg-light">
                                            <option *ngFor="let c of categories" [value]="c.categoryid">{{ c.title }}
                                            </option>
                                        </select>
                                        <div *ngIf="errStep1 && f.categoryid.errors" class="invalid-feedback">
                                            <div *ngIf="f.categoryid.errors.required">Category is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Status *</label>
                                        <select formControlName="status" class="form-control bg-light">
                                            <option *ngFor="let s of statusList" [value]="s.value">{{ s.title }}
                                            </option>
                                        </select>
                                        <div *ngIf="errStep1 && f.status.errors" class="invalid-feedback">
                                            <div *ngIf="f.status.errors.required">Status is required.</div>
                                        </div>
                                    </div>
                                </div>                                
                            </div>
                            <div class="col-lg-12">
                                <div class="form-group text-center">
                                    <button (click)="next()" class="btn btn-main">Next</button>
                                </div>
                            </div>
                        </div>
                        <div class="content" [hidden]="step != 2">
                            <div class="row">
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <file-upload label="Featured Image" requiredFileType="image/*" [placeholderImg]="temp?.featuredimage" (fileSelected)="handleInputChange($event)"></file-upload>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <file-upload label="Additional Image" requiredFileType="image/*" [placeholderImg]="temp?.additionalimage" (fileSelected)="addhandleInputChange($event)"></file-upload>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label for="text">Body</label>
                                        <ckeditor formControlName="description" [editor]="Editor" [config]="configs" (ready)="onReady($event)">
                                        </ckeditor>
                                        <!--ckeditor
                                      formControlName="description" 
                                       [(ngModel)]="temp.description"
                                      [config]="configs">
                                    </ckeditor-->
                                        <!--ckeditor [(ngModel)]="htmlContent" formControlName="description" [config]="configa"></ckeditor-->
                                        <!--ckeditor
                                                formControlName="description" [config]="ckeConfig" 
                                                (ngModelChange)="onChange($event)"
                                        >
                                        </ckeditor-->
                                        <!--angular-editor  formControlName="description" [placeholder]="'Enter text here...'" [config]="config.editorConfig"></angular-editor-->
                                        <!--div>
                                           
                                        </div-->
                                        <div *ngIf="errStep2 && f.description.errors" class="invalid-feedback">
                                            <div *ngIf="f.description.errors.required">Description is required.</div>
                                            <div *ngIf="f.description.hasError('minlength') && !f.description.hasError('required')">
                                                Description should be minimum {{descriptionLength}} characters.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Meta Title</label>
                                        <input type="text" formControlName="metatitle" class="form-control bg-light" [ngClass]="{ 'is-invalid': errStep2 && f.metatitle.errors }" placeholder="Meta Title" />
                                        <div *ngIf="errStep2 && f.metatitle.errors" class="invalid-feedback">
                                            <div *ngIf="f.metatitle.errors.required">Meta Title is required.</div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group">
                                        <label>Meta Description</label>
                                        <input type="text" formControlName="metadescription" class="form-control bg-light" [ngClass]="{ 'is-invalid': errStep2 && f.metadescription.errors }" placeholder="Meta Description" />
                                        <div *ngIf="errStep2 && f.metadescription.errors" class="invalid-feedback">
                                            <div *ngIf="f.metadescription.errors.required">Meta Description is required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="form-group keywords">
                                        <label>Meta Keywords</label>
                                        <tag-input formControlName="metakeywords" label="Meta Keywords"></tag-input>
                                        <div *ngIf="errStep2 && f.metakeywords.errors" class="invalid-feedback">
                                            <div *ngIf="f.metakeywords.errors.required">Meta Keywords are required.
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div class="d-flex align-items-center">
                                <button class="btn btn-main mr-3" (click)="previous()">Previous</button>
                                <button class="btn btn-main" (click)="submitModal(modal)">Submit</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</ng-template>

<ng-template #delConf let-modal>
    <div class="modal-header">
        <h4 class="modal-title" id="modal-title">Delete {{selectedType}}</h4>
        <button type="button" class="close" aria-label="Close button" aria-describedby="modal-title" (click)="modal.dismiss()">
            <span aria-hidden="true">&times;</span>
        </button>
    </div>
    <div class="modal-body">
        <p><strong>Are you sure you want to delete this {{selectedType}}?</strong></p>
        <p>All information associated to this {{selectedType}} will be deleted.</p>
    </div>
    <div class="modal-footer text-center">
        <button type="button" class="btn btn-outline-secondary" (click)="modal.dismiss()">Cancel</button>
        <button type="button" ngbAutofocus class="btn btn-danger" (click)="delBlog(modal)">Ok</button>
    </div>
</ng-template>