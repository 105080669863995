<div class="d-flex" style="background-color:#f1f5f8">
	<div class="col-lg-3 p-4">
		<app-left-side></app-left-side>
	</div>
	<div class="col-lg-9 p-4">
		<div class="shadow-lg rounded-lg bg-white p-4">
				<h5 class="text-primary">Employee Profile</h5>			
				<form>
				<div class="row">
					<div class="col-lg-6">
						 <div class="form-group">
						    <label for="text">First Name</label>
						    <input type="text" class="form-control bg-light" placeholder="First Name" id="f_name">
						  </div>
					</div>
					<div class="col-lg-6">
						 <div class="form-group">
						    <label for="text">Last Name</label>
						    <input type="text" class="form-control bg-light" placeholder="Last Name" id="l_name">
						  </div>
					</div>
					<div class="col-lg-6">
						 <div class="form-group">
						    <label for="file">Image File</label>
						    <input type="file" class="form-control bg-light" placeholder="File" id="file">
						  </div>
					</div>
					<div class="col-lg-6">
						 <div class="form-group">
						    <label for="text">Role</label>
						    <input type="text" class="form-control bg-light" placeholder="Role" id="u_name">
						  </div>
					</div>
						<div class="col-lg-6">
						 <div class="form-group">
						    <label for="text">Desination</label>
						    <input type="text" class="form-control bg-light" placeholder="Desination" id="u_name">
						  </div>
					</div>
					<div class="col-lg-6">
						 <div class="form-group">
						    <label for="text">Contact</label>
						    <input type="text" class="form-control bg-light" placeholder="Contact" id="c_name">
						  </div>
					</div>
					<div class="col-lg-12">
				         <button type="submit" class="btn btn-main text-center m-auto mt-4">Submit</button>
					</div>
				</div>
			</form>	
		</div>			
	</div>	
</div>