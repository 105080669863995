<!--============= Nav Area start here =============== bg-color-->
<div class="total-content" id="topnav">
    <div class="header-section">
        <nav class="navbar navbar-expand-lg">
            <button class="navbar-toggler" type="button" data-toggle="collapse" data-target="#topnavbar" aria-controls="topnavbar" aria-expanded="false" aria-label="Toggle navigation">
              <span class="navbar-toggler-icon"></span>
            </button>
            <div class="collapse navbar-collapse" id="topnavbar">
                <!-- <a class="navbar-brand" href="#">Hidden brand</a> -->
                <div class="navbar-brand">
                    <a routerLink="/dashboard" class="logo">
                        <img src="https://www.hwcindia.org/assets/images/logo/logo_white.png" alt="logo" class="img-fluid  max-width-logo" />
                    </a>
                </div>

                <a href="#" data-bs-target="#sidebar" data-bs-toggle="collapse" class="left-toggle text-decoration-none">
                    <i class="fa fa-arrow-left" aria-hidden="true"></i>
                </a>

                <ul class="navbar-nav mr-auto mt-2 mt-lg-0">
                    <li class="nav-item dropdown">
                        <h1 class="page-title">Dashboard</h1>
                    </li>
                </ul>
                <div class="navbar-right ml-auto me-4">
                    <ul class="navbar-nav mr-auto">
                        <li class="nav-item dropdown">
                            <a class="nav-link dropdown-toggle about_us_header" href="#" id="navbarDropdown" role="button" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <small class="d-inline-grid">{{auth.currentUser?.firstName}} {{auth.currentUser?.lastName}}
                            <small>({{auth.currentUser?.designation}})</small>
                                </small>
                                <img class="img-fluid profile-img" src="assets/img/male-placeholder.jpeg">
                                <i class="fa fa-angle-down" aria-hidden="true"></i>
                            </a>
                            <div class="dropdown-menu" aria-labelledby="navbarDropdown">
                                <a class="dropdown-item" *ngIf="!auth.currentUser?.userId" routerLink="/login">Log In</a>
                                <a class="dropdown-item" *ngIf="!auth.currentUser?.userId" routerLink="/register">Register</a>
                                <a class="dropdown-item" *ngIf="!auth.currentUser?.userId" routerLink="/forgetpassword">Forgot Password</a>
                                <a class="dropdown-item" *ngIf="auth.currentUser?.userId" routerLink="/profile">Profile</a>
                                <a class="dropdown-item" *ngIf="auth.currentUser?.userId" routerLink="/change-password">Change Password</a>
                                <a class="dropdown-item" *ngIf="auth.currentUser?.userId" routerLink="/dashboard">Dashboard</a>
                                <a class="dropdown-item" *ngIf="auth.currentUser?.userId" (click)="logout()">Logout</a>
                            </div>
                        </li>
                    </ul>
                </div>
            </div>
        </nav>
        <!-- navbar -->
        <!-- <nav class="navbar navbar-area navbar-expand-lg">
            <div class="container nav-container bg-nav row">
                <div class="responsive-mobile-menu col-lg-3">
                    <div class="logo">
                        <a routerLink="/dashboard" class="text-dark">
                            <img src="https://www.hwcindia.org/assets/images/logo/logo_white.png" alt="logo" class="img-fluid  max-width-logo" />
                        </a>
                    </div>
                </div>
                <div class="navbar-toggler  cross-menu" (click)="openNav()">
                    <span class="cross-menu-wrapper">
                        <span class="bar1"></span>
                    <span class="bar2"></span>
                    <span class="bar3"></span>
                    </span>
                </div>
                <div id="mySidenav" class="sidenav d-block d-lg-none">
                    <a href="javascript:void(0)" class="closebtn" (click)="closeNav()">&times;</a>
                    <div class="navbar-collapse">
                        <ul class="navbar-nav">
                            <li><a (click)="closeNav()" routerLinkActive="active" routerLink="/dashboard">Dashboard</a></li>
                            <li class="dropdown  menu-item-has-children">
                                <a class="dropdown-toggle about_us_header" id="dropdownMenuButton" data-toggle="dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="true">
                                    <h6>{{auth.currentUser?.firstName}} {{auth.currentUser?.lastName}}</h6>
                                    <p class="mt-3">({{auth.currentUser?.designation}})</p>
                                </a>
                                <ul class="dropdown-menu sub-menu border-0" aria-labelledby="dropdownMenuButton">
                                    <li><a *ngIf="!auth.currentUser?.userId" routerLink="/login">Log In</a></li>
                                    <li><a *ngIf="!auth.currentUser?.userId" routerLink="/register">Register</a></li>
                                    <li><a *ngIf="!auth.currentUser?.userId" routerLink="/forgetpassword">Forgot Password</a></li>
                                    <li><a *ngIf="auth.currentUser?.userId" routerLink="/profile">Profile</a></li>
                                    <li><a *ngIf="auth.currentUser?.userId" routerLink="/change-password">Change Password</a></li>
                                    <li><a *ngIf="auth.currentUser?.userId" routerLink="/dashboard">Dashboard</a></li>
                                    <li><a *ngIf="auth.currentUser?.userId" class="cursor" (click)="logout()">Logout</a></li>
                                </ul>
                            </li>
                        </ul>
                    </div>
                </div>
                <div class="collapse navbar-collapse d-flex justify-content-end col-lg-9" id="lifeomic_main_menu">
                    <ul class="navbar-nav navbar-nav-header d-flex align-items-center">
                        <li class="dropdown  menu-item-has-children nav-item ">
                            <a class="dropdown-toggle about_us_header" id="dropdownMenuButton" data-toggle="dropdown" data-bs-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                                <small class="d-inline-grid">{{auth.currentUser?.firstName}} {{auth.currentUser?.lastName}}
                                <br>
                                <small>({{auth.currentUser?.designation}})</small>
                                </small>

                                <img class="img-fluid profile-img" src="assets/img/male-placeholder.jpeg">
                            </a>
                            <ul class="dropdown-menu p-3 sub-menu border-0 dropdown-menu-right" aria-labelledby="dropdownMenuButton">
                                <li><a *ngIf="!auth.currentUser?.userId" routerLink="/login">Log In</a></li>
                                <li><a *ngIf="!auth.currentUser?.userId" routerLink="/register">Register</a></li>
                                <li><a *ngIf="!auth.currentUser?.userId" routerLink="/forgetpassword">Forgot Password</a></li>
                                <li><a *ngIf="(auth.currentUser?.roles[0] ==  'admin') || (auth.currentUser?.roles[0] == 'superadmin')" routerLink="/dashboard">Dashboard</a></li>
                                <li><a *ngIf="auth.currentUser?.userId" routerLink="/profile">Profile</a></li>
                                <li><a *ngIf="auth.currentUser?.userId" routerLink="/change-password">Change Password</a></li>
                                <li><a *ngIf="auth.currentUser?.roles[0] ==  'superadmin'" routerLink="/blog-list" [queryParams]="{selectedType: 'Blog'}">Blog list</a></li>
                                <li><a *ngIf="auth.currentUser?.roles[0] ==  'superadmin'" routerLink="/blog-list" [queryParams]="{selectedType: 'News'}">News list</a></li>
                                <li><a *ngIf="auth.currentUser?.userId" class="cursor" (click)="logout()">Logout</a></li>
                            </ul>
                        </li>
                    </ul>
                </div>
            </div>
        </nav> -->
    </div>
</div>
<!--============= Nav Area End here ===============-->