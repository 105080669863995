import { Injectable } from "@angular/core";
import { AngularEditorConfig } from "@kolkov/angular-editor";

@Injectable({
    providedIn: "root",
})
export class Config {
    constructor() {}

    public application = "HWC";
    public ApplicationId = "3947d951-b26e-4c2b-bdfc-2fafdbda5418";
    public tagline = "Dashboard";

    public appUri: string = "https://api.daani.in";
    public baseUri: string = "https://api.daani.in";
    public apiKey: string = "Service qrgUGrVfzKnl7dEAmwR6LLbprbuuqtzm";

    public isLoading: boolean = false; // Never edit this directly, call the functions

    public getSaltKey() {
        return this.ApplicationId;
    }

    public decrypt(encoded: any) {
        const salt = this.getSaltKey();
        const textToChars = (text: string) => text.split("").map((c) => c.charCodeAt(0));
        const applySaltToChar = (code: any) => textToChars(salt).reduce((a, b) => a ^ b, code);
        return encoded
            .match(/.{1,2}/g)
            .map((hex: any) => parseInt(hex, 16))
            .map(applySaltToChar)
            .map((charCode: any) => String.fromCharCode(charCode))
            .join("");
    }

    /**
     * Show a loader
     */
    public showLoading() {
        this.isLoading = true;
    }

    /**
     * Dismiss loader
     */
    public dismissLoading() {
        this.isLoading = false;
    }

    public defaultError = {
        title: "",
        detail: "Unexpected Error Occured! Please try again...",
    };

    public tokens = {
        auth: "",
        refresh: "",
    };

    public editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: "15rem",
        minHeight: "5rem",
        placeholder: "Enter text here...",
        translate: "no",
        defaultParagraphSeparator: "p",
        defaultFontName: "Arial",
        //uploadUrl: 'https://api.hwcindia.org/blogs/media/v1',
        // upload: (file: File) => { ... },
        uploadWithCredentials: false,
        sanitize: true,
        toolbarPosition: "top",
        toolbarHiddenButtons: [["bold"]],
        customClasses: [
            {
                name: "quote",
                class: "quote",
            },
            {
                name: "redText",
                class: "redText",
            },
            {
                name: "titleText",
                class: "titleText",
                tag: "h1",
            },
        ],
    };

    public statusList = [
        {
            value: "draft",
            title: "Draft",
        },
        /* {
      value: 'inreview',
      title: 'In Review',
    },*/
        {
            value: "hold",
            title: "On Hold",
        },
        /*{
      value: 'pending',
      title: 'Pending',
    },*/
        {
            value: "published",
            title: "Published",
        },
        /* {
      value: 'completed',
      title: 'Completed',
    },*/
        {
            value: "deleted",
            title: "Deleted",
        },
    ];
}
