<div class="container-fluid p-0">
   <div class="row flex-nowrap content-wrapper">
      <div class="col-auto px-0">
         <div id="sidebar" class="left-nav collapse collapse-horizontal show">
            <app-left-side></app-left-side>
         </div>
      </div>
      <main class="col ps-md-2 pt-2 content-overflow">
         <div class="right-content">
            <div class="px-4 py-3"> 
               <div class="row">
                  <div class="col-lg-12">
                     <div class="card rounded-4 mb-4 bg-white card-box">
                        <div class="card-head d-flex">
                           <header class="flex-grow-1">Employees <span>(#)</span></header>
                           <div class="tools">
                              <div class="accordion-button" type="button">
                                 <input class="filter-input form-control" name="searchTerm" [(ngModel)]="tableService.searchTerm" placeholder="Search..." />
                                 <i (click)="reload()" class="icon-line-awesome-rotate-left reload-action"></i>
                              </div>                               
                              <button type="button" (click)="showAddModal(content)" class="btn btn-outline-secondary add-btn">
                                 Add New EMP 
                              </button>                              
                              <button class="accordion-button with-chevron btn-icon" type="button" data-bs-toggle="collapse" data-bs-target="#tbcollapse" aria-expanded="true" aria-controls="collapseOne">
                                 <i class="fa fa-angle-down"></i>
                               </button> 
                           </div>
                        </div>
                        <div class="card-body p-0" id="tbcollapse">
                           <div class="table-wrap">
                              <div class="table-responsive">
                                 <table class="table table-hover display product-overview mb-30">
                                    <thead>
                                       <tr>
                                          <th [ngClass]="c.columnname === '' ? '' : 'cursor-pointer'" *ngFor="let c of displayedColumns" [sortable]="c.columnname" (sort)="onSort($event)">{{ c.displayname }}</th>
                                       </tr>
                                    </thead>
                                    <tbody>
                                       <tr *ngFor="let e of resultList$ | async; let i = index">
                                          <td>{{i+1}}</td>
                                          <td>{{ e.firstname }} {{ e.middlename }} {{ e.lastname }}</td>
                                          <td>{{ e.phonenumber }}</td>
                                          <td>{{ e.email }}</td>
                                          <td><span class="badge text-bg-success">{{ e.designation }}</span></td>
                                          <td>{{ e.lastlogintime | date : 'short'}}</td>
                                          <td>{{ e.lockoutenabled == true ? 'Inactive' : 'Active' }}</td>
                                          <td>
                                             <!-- <i class="icon-feather-edit text-primary mr-2 cursor"></i>  -->
            
                                             <a href="javascript:void(0)" class="tblDelBtn" data-toggle="tooltip" data-placement="top" title="Delete Record">
                                                <i class="fa fa-trash-o"></i>
                                             </a>
                                             <a href="javascript:void(0)" class="tblEditBtn" (click)="showEdit(content, e)" data-toggle="tooltip" data-placement="top" title="Edit Record">
                                                <i class="fa fa-pencil"></i>
                                             </a>
                                             <a href="javascript:void(0)" class="tblviewBtn" data-toggle="tooltip" data-placement="top" title="View Detail">
                                                <i class="fa fa-eye"></i>
                                             </a>
                                             <!--i class="icon-feather-trash-2 cursor text-primary"></i-->
                                          </td>
                                       </tr>
                                    </tbody>
                                 </table>
                                 <div class="d-flex justify-content-between p-2 my-pag" [hidden]="show == false">
                                    <ngb-pagination [collectionSize]="emps.length" [pageSize]="pageSize" [(page)]="pageIndex" [maxSize]="5" [rotate]="true" (pageChange)="onPagination($event)"></ngb-pagination>
                                    <select class="custom-select pagination" [(ngModel)]="pageSize" (ngModelChange)="setPageSize()">
                                       <option [ngValue]="10">10 items per page</option>
                                       <option [ngValue]="50">50 items per page</option>
                                       <option [ngValue]="100">100 items per page</option>
                                    </select>
                              </div>
                              </div>
                           </div>
                        </div>
                    </div>

                     
                  </div>
               </div>
            </div>
         </div>
      </main>
	</div>
</div>
<ng-template #content let-modal>
   <div class="modal-content shadow-lg">
      <div class="modal-header">
         <h5 class="modal-title" id="exampleModalLabel">{{action}} Employee</h5>
         <button type="button" class="btn-close" (click)="modal.dismiss()"></button>
     </div>
      <div class="modal-body">
         <!-- <div class="row">
            <div class="col-lg-6">
               <h6>{{action}} Employee</h6>
            </div>
            <div class="col-lg-6 text-lg-right" (click)="modal.dismiss()">
               <i aria-label="Close" class="icon-feather-x cursor text-primary"></i>
            </div>
         </div> -->
         <div class="card-body">
            <form [formGroup]="userForm">
               <div class="row">
                  <div class="col-lg-6">
                     <div class="form-group">
                        <label>First Name *</label>
                        <input type="text" formControlName="firstname" class="form-control bg-light" placeholder="First Name"/>
                        <div *ngIf="showErr && userForm.controls.firstname.errors" class="invalid-feedback">
                           <div *ngIf="userForm.controls.firstname.errors.required">Name is required</div>
                       </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="form-group">
                        <label>Middle Name</label>
                        <input type="text" formControlName="middlename" class="form-control bg-light" placeholder="Middle Name"/>
                        <div *ngIf="showErr && userForm.controls.middlename.errors" class="invalid-feedback">
                           <div *ngIf="userForm.controls.middlename.errors.required">Name is required</div>
                       </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="form-group">
                        <label>Last Name *</label>
                        <input type="text" formControlName="lastname" class="form-control bg-light" placeholder="Last Name"/>
                        <div *ngIf="showErr && userForm.controls.lastname.errors" class="invalid-feedback">
                           <div *ngIf="userForm.controls.lastname.errors.required">Name is required</div>
                       </div>
                     </div>
                  </div>
                  <div class="col-lg-6" *ngIf="temp.rolename != 'superadmin' && temp.rolename != 'admin'">
                     <div class="form-group">
                        <label>Designation *</label>
                        <select formControlName="role" class="form-control bg-light">
                           <option *ngFor="let r of roles" [hidden]="!r.isactive" [value]="r.name">{{ r.description }}</option>
                        </select>
                        <div *ngIf="showErr && userForm.controls.role.errors" class="invalid-feedback">
                           <div *ngIf="userForm.controls.role.errors.required">Role is required</div>
                       </div>
                     </div>
                  </div>
                  <div class="col-lg-6">
                     <div class="form-group">
                        <label>Phone *</label>
                        <input type="text" formControlName="phonenumber"  class="form-control bg-light" placeholder="Phone"/>
                        <div *ngIf="showErr && userForm.controls.phonenumber.errors" class="invalid-feedback">
                           <div *ngIf="userForm.controls.phonenumber.errors.required">Phone is required</div>
                           <div *ngIf="(userForm.controls.phonenumber.hasError('maxlength') || userForm.controls.phonenumber.hasError('minlength')) && !userForm.controls.phonenumber.hasError('required')">Please enter a valid phone number.</div>
                       </div>
                     </div>
                  </div>
                  <div class="col-lg-6" *ngIf="action == 'Add'">
                     <div class="form-group">
                        <label>Email *</label>
                        <input type="email" formControlName="email" class="form-control bg-light" placeholder="Email"/>
                        <div *ngIf="showErr && userForm.controls.email.errors" class="invalid-feedback">
                           <div *ngIf="userForm.controls.email.errors.required">Email is required</div>
                           <div *ngIf="userForm.controls.email.hasError('pattern') && !userForm.controls.email.hasError('required')">Please enter a valid email.</div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6" *ngIf="action == 'Add'">
                     <div class="form-group">
                        <label>Password *</label>
                        <input type="text" formControlName="password" class="form-control bg-light" placeholder="password"/>
                        <div *ngIf="showErr && userForm.controls.password.errors" class="invalid-feedback">
                           <div *ngIf="userForm.controls.password.errors.required">Email is required</div>
                           <div *ngIf="userForm.controls.password.hasError('pattern') && !userForm.controls.password.hasError('required')">Please enter a valid password.</div>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-6" *ngIf="temp.rolename != 'superadmin'">
                     <div class="form-group">
                        <label>Emp. Status</label>
                        <div class="custom-control custom-switch custom-switch-lg mb-4">
                           <input name="chkbx" type="checkbox" formControlName="status" class="custom-control-input" id="switch1">
                           <label class="custom-control-label" for="switch1">{{ userForm.controls.status.value == true ? 'Active' : 'Inactive' }}</label>
                        </div>
                     </div>
                  </div>
                  <div class="col-lg-12">
                     <button (click)="submitModal(modal)" class="btn btn-main text-center m-auto mt-4">Submit</button>
                  </div>
               </div>
            </form>
         </div>
      </div>
   </div>
</ng-template>