<section class="admin-login-section">
    <div class="container py-5">
        <div class="row">
            <div class="col-lg-4 m-auto">
                <div class="login-admin-container">
                    <div class="row">
                        <div class="col-lg-12">
                            <div class="text-center my-3">
                                <img src="https://www.hwcindia.org/assets/images/logo/logo_white.png" class="img-fluid max-width-185">
                            </div>
                            <form class="contact-form " [formGroup]="loginForm" (ngSubmit)="onSubmitLogin()">
                                <div class="form-group position-relative">
                                    <i class="icon-feather-user icon-set ml-2"></i>
                                    <input type="email" class="form-control text-center admin-login-textbox" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email" />
                                    <div *ngIf="(submitted || f.email.touched) && f.email.errors" class="invalid-feedback">
                                        <div *ngIf="f.email.errors.required">Email is required.</div>
                                        <div *ngIf="f.email.hasError('pattern')">Please enter a valid email.</div>
                                    </div>
                                </div>
                                <div class="form-group position-relative">
                                    <i class="icon-material-outline-https icon-set  ml-2"></i>
                                    <input type="password" class="form-control text-center admin-login-textbox" formControlName="password" [ngClass]="{ 'is-invalid': submitted && f.password.errors }" placeholder="Password" />
                                    <div *ngIf="(submitted || f.password.touched) && f.password.errors" class="invalid-feedback">
                                        <div *ngIf="f.password.errors.required">Password is required</div>
                                    </div>
                                </div>
                                <input type="submit" class="btn  d-block text-center m-auto admin-login-now-btn" value="Login Now" />
                                <a routerLink="/forgetpassword" class="text-white my-3 text-center d-block">Forgot Password</a>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>