import { Component, OnInit } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ToastrService } from "ngx-toastr";
import { AppAuth } from "src/app/services/app-auth.service";
import { Config } from "src/app/services/config";
import { AppCsvService } from "src/app/services/appcsv.service";

@Component({
    selector: "app-receipt-list",
    templateUrl: "./receipt-list.component.html",
    styleUrls: ["./receipt-list.component.css"],
})
export class ReceiptListComponent implements OnInit {
    action: string = "Add";
    userForm: FormGroup;
    pattern: RegExp = /^( )*([A-Za-z0-9_\-\.+])+\@([A-Za-z0-9_\-\.])+\.([A-Za-z]{2,6})( )*$/;
    showErr: boolean = false;
    //receiptList: Array<any> = [];
    receiptList: any = [];
    selectedReceipt: any = null;
    selectedReceiptIndex: any = null;
    downloadForm: FormGroup;
    importCSVForm: FormGroup;
    uReceiptForm: FormGroup;
    showDownloadErr: boolean = false;
    url: any;
    document_image: any;
    //data: any;
    base64data: any;
    dtOptions: any = {};
    sourceList = [
        {
            sourcename: "HWC",
            sourcevalue: "HWC",
        },
        {
            sourcename: "PGC",
            sourcevalue: "PGC",
        },
        {
            sourcename: "LITINDIA",
            sourcevalue: "LITINDIA",
        },
        {
            sourcename: "DAANI",
            sourcevalue: "DAANI",
        },
    ];
    paymentModeList = [
        {
            paymenttype: "ONLINE",
            paymentname: "ONLINE",
        },
        {
            paymenttype: "OFFLINE",
            paymentname: "OFFLINE",
        },
    ];

    constructor(private modalService: NgbModal, private fb: FormBuilder, public config: Config, public auth: AppAuth, public toastr: ToastrService, private appCsvService: AppCsvService) {}

    ngOnInit(): void {
        let columnsArr = [0, 1, 2, 3, 4, 5, 6, 7, 8];
        this.dtOptions = {
            pagingType: "full_numbers",
            pageLength: 5,
            order: [[7, "desc"]],
            processing: false,
            dom: "Bfrtip",
            responsive: true,
            buttons: [
                { extend: "csv", className: "btn btn-primary btn-sm", exportOptions: { columns: columnsArr } },
                { extend: "excel", className: "btn btn-success btn-sm", exportOptions: { columns: columnsArr } },
                { extend: "copy", className: "btn btn-warning btn-sm", exportOptions: { columns: columnsArr } },
                { extend: "print", className: "btn btn-danger btn-sm", exportOptions: { columns: columnsArr } },
            ],
        };
        this.userForm = this.fb.group({
            name: ["", [Validators.required]],
            email: ["", [Validators.required, Validators.email, Validators.maxLength(50), Validators.pattern(this.pattern)]],
            mobile: ["", [Validators.required, Validators.minLength(10), Validators.maxLength(14)]],
            txnamount: ["", Validators.required],
            source: ["", Validators.required],
            campaignid: [""],
            paymentmode: ["", Validators.required],
            banktxnid: [""],
            pancard: ["", Validators.required],
            reason: ["", Validators.required],
            donationurl: ["", Validators.required],
        });

        this.uReceiptForm = this.fb.group({
            name: ["", [Validators.required]],
            txnamount: ["", Validators.required],
            pancard: [""],
            reason: [""],
            donationurl: [""],
        });

        this.downloadForm = this.fb.group({
            ptid: ["", Validators.required],
        });

        this.importCSVForm = this.fb.group({
            ptid: ["", Validators.required],
        });

        this.getReceiptList();
    }

    // Add Receipt
    public showAddModal(content: any) {
        this.showErr = false;
        this.action = "Add";
        this.userForm.reset();
        this.modalService.open(content, { ariaLabelledBy: "modal-basic-title-add" });
    }

    public submitModal(ref: any) {
        this.showErr = true;
        this.addReceipt(ref);
    }

    // Download Receipt
    /*public showDownloadModal(download: any) {
        this.showDownloadErr = false;
        this.downloadForm.reset();
        this.modalService.open(download, { ariaLabelledBy: "modal-basic-title-add" });
    }

    public submitDownloadModal(ref: any) {
        this.showDownloadErr = true;
        //this.downloadReceipt(ref);
    }*/

    public getReceiptList() {
        this.config.showLoading();
        this.auth.getReceiptList().then(
            (res: any) => {
                this.receiptList = res;
                setTimeout(() => {
                    this.config.dismissLoading();
                }, 1000);
            },
            (err: any) => {
                this.toastr.error(err.title, err.detail);
                this.config.dismissLoading();
            }
        );
    }

    private addReceipt(ref: any) {
        let v = this.userForm.value;
        if (this.userForm.valid) {
            this.config.showLoading();

            let data = {
                name: v.name,
                email: v.email,
                mobile: v.mobile,
                txnamount: v.txnamount,
                source: v.source,
                campaignid: v.campaignid,
                paymentmode: v.paymentmode,
                banktxnid: v.banktxnid,
                reason: v.reason,
                pancard: v.pancard,
                donationurl: v.donationurl,
            };
            this.auth.addReceipt(data).then(
                (res: any) => {
                    this.showErr = false;

                    this.getReceiptList();
                    // loader will get dismissed in above function call, no need to call dismiss here

                    ref.close();
                },
                (err: any) => {
                    this.config.dismissLoading();
                    this.toastr.error(err.title, err.detail);
                }
            );
        } else {
            this.showErr = true;
        }
    }

    public sendReceiptEmail(ptid: any, sendemail: boolean, name: any, invoiceid: any) {
        if (confirm("Are you sure to Resend E-Receipt Email To- " + name + " (" + invoiceid + ")")) {
            this.downloadReceipt(ptid, sendemail);
        }
    }

    public discardReceipt(ptid: any, name: any, invoiceid: any) {
        if (confirm("Are you sure to Discard this E-Receipt of- " + name.toUpperCase() + " (" + invoiceid + ")")) {
            this.config.showLoading();
            this.auth.deleteInvoice({ ptid: ptid }).then(
                (res: any) => {
                    this.toastr.success("Success", res.message);
                    this.receiptList = this.receiptList.filter((r: any) => r.ptid != ptid);
                    this.config.dismissLoading();
                },
                (err: any) => {
                    this.toastr.error(err.title, err.detail);
                    this.config.dismissLoading();
                }
            );
        }
    }

    public showUpdateModal(receipt: any, index: any, ref: any) {
        this.selectedReceipt = receipt;
        this.selectedReceiptIndex = index;
        this.showErr = false;
        this.uReceiptForm.reset();
        this.modalService.open(ref, { ariaLabelledBy: "modal-basic-title-add" });
    }

    public updateReceiptSubmit(ref: any) {
        let v = this.uReceiptForm.value;
        if (this.uReceiptForm.valid) {
            this.config.showLoading();
            let updateObj = {
                ptid: this.selectedReceipt.ptid,
                name: v.name ? v.name : this.selectedReceipt.name,
                txnamount: v.txnamount ? v.txnamount : this.selectedReceipt.txnamount,
                pancard: v.pancard ? v.pancard : this.selectedReceipt.pancard,
                reason: v.reason ? v.reason : this.selectedReceipt.reason,
                donationurl: v.donationurl ? v.donationurl : this.selectedReceipt.donationurl,
            };
            this.auth.updateInvoice(updateObj).then(
                (res: any) => {
                    this.toastr.success("Success", res.message);
                    this.receiptList[this.selectedReceiptIndex] = res.receipt;
                    this.config.dismissLoading();
                    if (ref) {
                        ref.close();
                    }
                },
                (err: any) => {
                    this.toastr.error(err.title, err.detail);
                    this.config.dismissLoading();
                }
            );
        } else {
            this.showErr = true;
        }
    }

    public downloadReceipt(ptid: any, sendemail: boolean) {
        if (ptid) {
            this.config.showLoading();
            this.auth.getInvoice(ptid, "base64", sendemail).then(
                (res: any) => {
                    if (sendemail) {
                        this.toastr.success("Success", "Receipt send!");
                    } else {
                        this.getPdf(res);
                    }
                    setTimeout(() => {
                        this.config.dismissLoading();
                    }, 1000);
                },
                (err: any) => {
                    this.toastr.error(err.title, err.detail);
                    this.config.dismissLoading();
                }
            );
        }
    }

    public strToNumber(str: any) {
        return parseFloat(str);
    }

    /*public b64toBlob(b64Data: any, contentType: any) {
        contentType = contentType || "";
        let sliceSize = 512;

        var byteCharacters = atob(b64Data);
        var byteArrays = [];

        for (var offset = 0; offset < byteCharacters.length; offset += sliceSize) {
            var slice = byteCharacters.slice(offset, offset + sliceSize);

            var byteNumbers = new Array(slice.length);
            for (var i = 0; i < slice.length; i++) {
                byteNumbers[i] = slice.charCodeAt(i);
            }

            var byteArray = new Uint8Array(byteNumbers);

            byteArrays.push(byteArray);
        }

        var blob = new Blob(byteArrays, { type: contentType });
        return blob;
    }*/

    public getPdf(downloadPdfData: any) {
        const source = `data:application/pdf;base64,${downloadPdfData.base64String}`;
        const link = document.createElement("a");
        link.href = source;
        link.download = `${downloadPdfData.name}`;
        link.click();
    }

    /*public showImportCSVModal(importcsv: any) {
        this.showErr = false;
        this.userForm.reset();
        this.modalService.open(importcsv, { ariaLabelledBy: "modal-basic-title-add" });
    }

    public submitImportCSVModal(ref: any) {
        this.showErr = true;
        console.log("submit");
    }

    public ondocumentSelectFile(event: any) {
        console.log(event.target);
        if (event.target.files && event.target.files[0]) {
            var reader = new FileReader();
            let filename = event.target.files[0].name;
            console.log(filename);

            reader.readAsDataURL(event.target.files[0]); // read file as data url

            reader.onload = (event: any) => {
                // called once readAsDataURL is completed
                this.url = event.target.result;
                let params = (reader.result as string).replace(/^.+?;base64,/, "");
                let userid = this.auth.currentUser.userId;
                this.auth.uploadMediaStories(userid, filename, params).then(
                    (data: any) => {
                        this.document_image = data.url;
                        console.log(data);
                    },
                    (error) => {
                        console.log(error);
                    }
                );
            };
        }
    }*/
}
