<section class="admin-login-section guest-bg">
    <div class="container py-5">
        <div class="login-admin-container">
            <div class="row">
                <div class="col-lg-12">
                    <h4 class="login-color-gray">Can't Sign-In? Forget your passward?</h4>
                    <p class="login-color-gray">Enter your email address below &amp; we will send you password reset instructions:</p>
                </div>
                <div class="col-lg-8">
                    <form class="contact-form bg-white shadow rounded p-4" [formGroup]="forgetForm" (ngSubmit)="forgotPass()">
                        <div class="row">
                            <div class="col-lg-6">
                                <input type="email" class="form-control admin-login-textbox" formControlName="email" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email" />
                                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                                    <div *ngIf="f.email.hasError('required')">Email is required</div>
                                    <div *ngIf="f.email.hasError('pattern') && !f.email.hasError('required')">Please enter a valid email.</div>
                                </div>
                                <input type="submit" class="btn admin-login-now-btn" value="Send me reset instruction" />
                            </div>
                        </div>
                    </form>
                </div>
                <div class="col-lg-4">
                    <div class="admin-login-account-section">
                        <p class="login-color-gray">Don't have an account?</p>
                        <a routerLink="/login" class="createaccountbtn mb-3">Login Now</a>
                    </div>
                </div>
            </div>
        </div>
    </div>
</section>