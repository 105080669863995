<!--Footer-->
<footer class="footer position-fixed">
  <div class="copyright-area">
     <div class="nav-container">
        <div class="p-3">
           <div class="col-lg-12">
              <div class="text-center">
                 <p class="qry-copy m-0">Copyright © 2021 <a class="text-primary"  href="https://concientotech.com/" target="_blank"><strong>Conciento-Tech</strong></a>. All rights reserved.</p>
              </div>
           </div>
        </div>
     </div>
  </div>
</footer>
<!--End-->
