import { Component, OnInit, ViewChild } from "@angular/core";
import { FormBuilder, FormGroup, Validators } from "@angular/forms";
import { ToastrService } from "ngx-toastr";
import { AppAuth } from "../../services/app-auth.service";
import { Config } from "src/app/services/config";
import { NgbModal } from "@ng-bootstrap/ng-bootstrap";
import { ChangeEvent, FocusEvent, BlurEvent } from "@ckeditor/ckeditor5-angular/ckeditor.component";
import Adapter from "./ckeditorAdapter";
import UploadAdapter from "./ckeditorAdapter";
import MyUploadAdapter from "./ckeditorAdapter";
import ClassicEditor from "@ckeditor/ckeditor5-build-classic";
import { RouterModule, Routes, ActivatedRoute } from "@angular/router";

@Component({
    selector: "app-blog-list",
    templateUrl: "./blog-list.component.html",
    styleUrls: ["./blog-list.component.css"],
})
export class BlogListComponent implements OnInit {
    blogForm: FormGroup;
    step: number = 1;
    errStep1: boolean = false;
    errStep2: boolean = false;
    submitting: boolean = false;
    action: string = "Add";
    temp: any = {};
    ckeConfig: any;
    mycontent: string;
    selectedType: string = "Blog";
    log: string = "";
    @ViewChild("myckeditor") ckeditor: any;
    categories: Array<any> = [];
    categoriesMain: Array<any> = [];
    statusList = [];
    Additionalimage: any;
    localUrl: any;
    htmlContent = "";
    descriptionLength: any = 500;
    titleLength: any = 250;
    blogs = {
        options: {
            limit: 10,
            pageno: 1,
            total: 0,
            offset: 0,
            search: "",
            cslug: "",
        },
        list: [],
    };
    // Editor
    public Editor = ClassicEditor;
    ckconfig;
    public editorData = "";
    public componentEvents: string[] = [];
    localImg: any;
    bs64: any;
    html: any;
    public configs = {
        //  toolbar: [ 'link' ],
        link: {
            decorators: {
                isExternal: {
                    mode: "manual",
                    label: "Open in a new tab",
                    attributes: {
                        target: "_blank",
                    },
                },
            },
        },
    };

    constructor(public formBuilder: FormBuilder, public toastr: ToastrService, public auth: AppAuth, public config: Config, private modalService: NgbModal, private activatedRoute: ActivatedRoute) {
        this.activatedRoute.queryParams.subscribe((params) => {
            if (params["selectedType"]) {
                this.selectedType = params["selectedType"];
            }
            //console.log(this.selectedType);
            this.tabChanged();
        });
    }

    ngOnInit(): void {
        this.statusList = this.config.statusList;

        this.blogForm = this.formBuilder.group({
            title: ["", [Validators.required, Validators.minLength(30), Validators.maxLength(this.titleLength)]],
            slug: ["", [Validators.required, Validators.minLength(30), Validators.maxLength(this.titleLength)]],
            categoryid: ["", [Validators.required]],
            status: ["", [Validators.required]],
            description: ["", [Validators.required, Validators.minLength(this.descriptionLength)]],
            metatitle: ["", []],
            metadescription: ["", []],
            metakeywords: ["", []],
            file: [""],
        });

        this.auth.getCategories().then(
            (cats: any) => {
                this.categoriesMain = cats;
                this.tabChanged();
            },
            (err: any) => {
                this.toastr.error(err.title, err.detail);
            }
        );
    }

    updateBlogNewsSlug() {
        var str = this.blogForm.controls.title.value;
        str = str.replace(/^\s+|\s+$/g, ""); // trim
        str = str.toLowerCase();

        // remove accents, swap ñ for n, etc
        var from = "ãàáäâẽèéëêìíïîõòóöôùúüûñç·/_,:;";
        var to = "aaaaaeeeeeiiiiooooouuuunc------";
        for (var i = 0, l = from.length; i < l; i++) {
            str = str.replace(new RegExp(from.charAt(i), "g"), to.charAt(i));
        }

        str = str
            .replace(/[^a-z0-9 -]/g, "") // remove invalid chars
            .replace(/\s+/g, "-") // collapse whitespace and replace by -
            .replace(/-+/g, "-"); // collapse dashes

        this.blogForm.controls.slug.setValue(str);
    }

    tabChanged() {
        if (this.selectedType == "Blog") {
            this.categories = this.categoriesMain.filter((a) => a.title != "News");
            this.descriptionLength = 500;
            this.titleLength = 250;
        } else if (this.selectedType == "News") {
            this.categories = this.categoriesMain.filter((a) => a.title == "News");
            this.descriptionLength = 100;
            this.titleLength = 250;
        } else {
            this.categories = this.categoriesMain;
        }
        this.getBlogs();
    }

    getBlogs() {
        this.config.showLoading();
        let op = this.blogs.options;
        let categoryid = null;
        if (this.selectedType == "News") {
            categoryid = "4f480fd9-0ecb-446d-8c03-91053d25d314";
        }
        //console.log("this.selectedType", this.selectedType);
        this.auth.getBlogs(op.limit, op.pageno, op.search, op.cslug, categoryid).then(
            (blog: any) => {
                this.blogs = blog;
                this.config.dismissLoading();
            },
            (err) => {
                this.config.dismissLoading();
                this.toastr.error(err.title, err.detail);
            }
        );
    }

    public onPagination(page: any) {
        this.getBlogs();
    }

    setPageSize() {
        this.getBlogs();
    }

    get f() {
        return this.blogForm.controls;
    }

    next() {
        let c = this.blogForm.controls;
        //console.log("this.titleLength", this.titleLength);
        //console.log("this.descriptionLength", this.descriptionLength);
        console.log(c);
        if (c.title.valid) {
            this.errStep1 = false; // no errors should display
            // If add-form, then submit blog with title
            if (this.action == "Add") {
                this.config.showLoading();
                this.newBlog();
            } else {
                // do nothing, blog object will retain values for final update
                this.step = 2;
            }
        } else {
            this.errStep1 = true; // display step 1 errors
        }
    }

    newBlog() {
        let c = this.blogForm.controls;

        let b = {
            categoryid: c.categoryid.value,
            title: c.title.value,
            featuredimage: "",
            youtubeurl: "",
            additionalimage: "",
            description: "",
            status: "published",
        };

        this.auth.addBlog(b).then(
            (res: any) => {
                this.step = 2;
                this.temp = res.blog;
                this.config.dismissLoading();
            },
            (err: any) => {
                this.config.dismissLoading();
                this.toastr.error(err.title, err.detail);
            }
        );
    }

    previous() {
        if (this.step > 1) {
            this.step--;
        }
    }

    showAddModal(content: any, type: any) {
        this.step = 1;
        this.action = "Add";
        this.blogForm.reset();
        this.temp = null;
        this.selectedType = type;
        this.tabChanged();
        this.modalService.open(content, { size: "lg", scrollable: false });
    }

    showEdit(content: any, blog: any) {
        this.step = 1;
        this.action = "Edit";
        this.blogForm.reset();
        this.tabChanged();
        this.temp = blog;
        this.setFormValues(blog);
        this.modalService.open(content, { size: "lg", scrollable: false });
    }

    private setFormValues(blog: any) {
        let b = this.blogForm.controls;

        b.title.setValue(blog.title);
        b.slug.setValue(blog.slug);
        b.description.setValue(blog.description);
        b.categoryid.setValue(blog.categoryid);
        b.status.setValue(blog.status);
        b.metatitle.setValue(blog.metatitle);
        b.metadescription.setValue(blog.metadescription);
        b.metakeywords.setValue(blog.metakeywords);
    }

    submitModal(ref: any) {
        let c = this.blogForm.controls;

        if (this.blogForm.valid) {
            this.config.showLoading();
            this.errStep2 = false; // no errors should display

            this.temp.title = c.title.value;
            this.temp.slug = c.slug.value;
            this.temp.description = c.description.value;
            this.temp.categoryid = c.categoryid.value;
            this.temp.status = c.status.value;
            this.temp.metatitle = c.metatitle.value;
            this.temp.metadescription = c.metadescription.value;
            this.temp.metakeywords = c.metakeywords.value;

            let bl = {
                bid: this.temp.bid,
                categoryid: this.temp.categoryid,
                title: this.temp.title,
                slug: this.temp.slug,
                featuredimage: this.temp.featuredimage || "",
                additionalimage: this.temp.additionalimage || "",
                youtubeurl: this.temp.youtubeurl || "",
                description: this.temp.description || "",
                metatitle: this.temp.metatitle || "",
                metakeywords: this.temp.metakeywords || "",
                metadescription: this.temp.metadescription || "",
                status: this.temp.status || "",
            };

            // Call blog update method here
            this.auth.updateBlog(bl).then(
                (res: any) => {
                    if (this.action == "Add") {
                        // Add new blog item to blog list
                        this.blogs.options.total = this.blogs.list.unshift(this.temp);
                    }

                    ref.close();
                    this.config.dismissLoading();
                },
                (err: any) => {
                    this.config.dismissLoading();
                    this.toastr.error(err.title, err.detail);
                }
            );
            // TODO: Handle file upload here
        } else {
            this.errStep2 = true; // display step 2 errors
        }
    }

    confirmDel(ref: any, blog: any) {
        this.temp = blog;
        this.modalService.open(ref, { ariaLabelledBy: "modal-basic-title-add" });
    }

    delBlog(ref: any) {
        this.config.showLoading();
        this.temp.status = "deleted";

        let bl = {
            bid: this.temp.bid,
            categoryid: this.temp.categoryid,
            title: this.temp.title,
            featuredimage: this.temp.featuredimage || "",
            additionalimage: this.temp.additionalimage || "",
            youtubeurl: this.temp.youtubeurl || "",
            description: this.temp.description || "",
            metatitle: this.temp.metatitle || "",
            metakeywords: this.temp.metakeywords || "",
            metadescription: this.temp.metadescription || "",
            status: "deleted",
        };

        // Call blog update method here
        this.auth.updateBlog(bl).then(
            (res: any) => {
                ref.close();
                this.config.dismissLoading();
            },
            (err: any) => {
                this.config.dismissLoading();
                this.toastr.error(err.title, err.detail);
            }
        );
    }

    handleInputChange(f: any) {
        this.config.showLoading();

        if (f.base64url && f.base64url.length > 0) {
            let sub = "data:" + f.type + ";base64,";
            let url = f.base64url.replace(sub, "");

            this.auth.uploadMediaBlog(this.temp.bid, f.name, url).then(
                (res: any) => {
                    if (res && res.url && res.url.length > 0) {
                        this.temp.featuredimage = res.url;
                    }

                    this.config.dismissLoading();
                },
                (err: any) => {
                    this.config.dismissLoading();
                    this.toastr.error(err.title, err.detail);
                }
            );
        }
    }

    addhandleInputChange(f: any) {
        this.config.showLoading();

        if (f.base64url && f.base64url.length > 0) {
            let sub = "data:" + f.type + ";base64,";
            let url = f.base64url.replace(sub, "");

            this.auth.uploadMediaBlog(this.temp.bid, f.name, url).then(
                (res: any) => {
                    if (res && res.url && res.url.length > 0) {
                        this.temp.additionalimage = res.url;
                        //console.log(this.temp.additionalimage);
                        this.Additionalimage = this.temp.additionalimage;
                    }

                    this.config.dismissLoading();
                },
                (err: any) => {
                    this.config.dismissLoading();
                    this.toastr.error(err.title, err.detail);
                }
            );
        }
    }
    onEditorChange(event: any) {
        console.log(event);
    }
    onChange(event: ChangeEvent): void {
        //console.log(event.editor.getData());
        this.componentEvents.push("Editor model changed.");
    }

    onFocus(event: FocusEvent): void {
        this.componentEvents.push("Focused the editing view.");
    }

    onBlur(event: BlurEvent): void {
        this.componentEvents.push("Blurred the editing view.");
    }
    onReady(editor: any) {
        let localImg = localStorage.getItem("img");
        let bs64 = localStorage.getItem("base64code");
        //console.log(localImg);
        //console.log(bs64);

        editor.plugins.get("FileRepository").createUploadAdapter = (loader) => {
            // console.log(loader)
            // console.log(editor.config.get( 'uploadUrl' ))
            //let sub = 'data:' + this.bs64 + ';base64,';
            //let url = this.bs64.replace(sub, '');

            this.auth.uploadMediaBlog(this.temp.bid, localImg, bs64).then(
                (res: any) => {
                    if (res && res.url && res.url.length > 0) {
                        this.temp.featuredimage = res.url;
                        localStorage.setItem("localUrl", res.url);
                        localStorage.removeItem("base64code");
                        localStorage.removeItem("img");
                    }

                    // this.config.dismissLoading();
                },
                (err: any) => {
                    // this.config.dismissLoading();
                    this.toastr.error(err.title, err.detail);
                }
            );
            //console.log(editor.config.get("uploadUrl"));
            return new Adapter(loader, editor.config.get("uploadUrl"), editor.t);
        };
        // editor.ui.view.editable.editableElement.style.height = '300px';
    }
}
