<div>
    <!--outlet-->
    <app-header *ngIf="showHeaderFooter"></app-header>
    <router-outlet></router-outlet>
    <app-footer *ngIf="showHeaderFooter"></app-footer>
    <!--outlet-->
</div>
<div class="app-loading bg-color" *ngIf="config.isLoading" [class.opaque]="preloader">
    <div class="logo"></div>
    <svg class="spinner" viewBox="25 25 50 50">
        <circle class="path" cx="50" cy="50" r="20" fill="none" stroke-width="2" stroke-miterlimit="10" />
    </svg>
</div>