<div class="container-fluid p-0">
    <div class="row flex-nowrap content-wrapper">
        <div class="col-auto px-0">
            <div id="sidebar" class="left-nav collapse collapse-horizontal show">
				<app-left-side></app-left-side>
			</div>
		</div>
		<main class="col ps-md-2 pt-2 content-overflow">
            <div class="right-content">
                <div class="px-4 py-3">
					<div class="row">
						<div class="col-lg-3">
							<!-- <div class="shadow-lg rounded-lg bg-white p-4 text-center">
								<img src="assets/img/profile.jpg" class="img-fluid rounded-circle">
								<h6>{{auth.currentUser?.firstName}} {{auth.currentUser?.lastName}}</h6>
								<small>{{auth.currentUser?.email}}</small>
								<div class="d-flex mt-3">
									<button type="button" class="btn btn-primary mr-3">Chat</button>
									<button type="button" class="btn btn-secondary">Follow</button>
								</div>
							</div> -->
							<!-- <div class="shadow-lg rounded-lg bg-white p-3  mt-4">
								<a href="javascript:void(0)" class="text-primary d-flex align-items-center "><i class="icon-feather-user mr-2 "></i> My Profile</a>
								<a href="javascript:void(0)" class="text-dark d-flex align-items-center mr-2"><i class="icon-feather-user mr-2"></i> Edit Profile</a>
								<a href="javascript:void(0)" class="text-dark d-flex align-items-center mr-2"><i class="icon-material-outline-https mr-2"></i> Reset Password</a>
								<a href="javascript:void(0)" (click)="logout()	" class="text-dark d-flex cursor align-items-center mr-2"><i class="icon-material-outline-https mr-2"></i> My Logout</a>
							</div> -->
							<div class="card rounded-4 mb-4 bg-white card-box">
								<div class="card-body text-center">
									<div class="profile-userpic">
										<img src="assets/img/profile.jpg" class="img-responsive" alt=""> 
									</div>
									<div class="profile-usertitle">
										<div class="profile-usertitle-name">{{auth.currentUser?.firstName}} {{auth.currentUser?.lastName}} </div>
										<div class="profile-usertitle-job"> {{auth.currentUser?.email}}</div>
									</div>
									<div class="d-flex justify-content-center mt-3">
										<button type="button" class="btn btn-primary mr-3">Chat</button>
										<button type="button" class="btn btn-secondary">Follow</button>
									</div>
								</div>								
							</div>
							<div class="card rounded-4 mb-4 bg-white card-box">
								<div class="card-head bdr-btm">
									<header>Quick Links</header>
								</div>
								<div class="card-body">
									<ul class="performance-list">
										<li>
											<a href="javascript:void(0)">
												<i class="fa fa-user-o" style="color:#F39C12;"></i> My Profile
											</a>
										</li>
										<li>
											<a href="#">
												<i class="fa fa-pencil-square-o" style="color:#DD4B39;"></i> Edit Profile
											</a>
										</li>
										<li>
											<a href="#">
												<i class="fa fa-key" style="color:#00A65A;"></i> Reset Password
											</a>
										</li>
										<li>
											<a href="javascript:void(0)" (click)="logout()" >
												<i class="fa fa-sign-out" style="color:#F39C12;"></i> My Logout
											</a>
										</li>
									</ul>
								</div>
							</div>
						</div>
						<div class="col-lg-9">
							<div class="card rounded-4 mb-4 bg-white card-box">
								<div class="card-head bdr-btm">
									<header class="">Change Password</header>
								</div>
								<div class="card-body form-update">
									<form>
										<div class="row">
											<div class="col-lg-12">
												<div class="form-group">
													<label for="password">Old Password</label>
													<input type="password" class="form-control bg-light" placeholder="Old Password" id="compaign">
												</div>
											</div>
											<div class="col-lg-12">
												<div class="form-group">
													<label for="password">New Passowrd</label>
													<input type="password" class="form-control bg-light" placeholder="New Password" id="compaign">
												</div>
											</div>
											<div class="col-lg-12">
												<div class="form-group">
													<label for="password">Confirm Password</label>
													<input type="password" class="form-control bg-light" placeholder="Confirm Password" id="compaign">
												</div>
											</div>
											<div class="col-lg-12">
												<div class="form-group text-center">
													<button type="submit" class="btn btn-main text-center m-auto mt-4">Submit</button>
												</div>
											</div>
										</div>	
									</form>	
								</div>
							</div>
							<!-- <div class="shadow-lg rounded-lg bg-white p-4">
							<h5 class="text-primary"></h5>			 -->
							
						</div>
						
					</div>
				</div>	
			</div>
		</main>
	</div>
</div>	

